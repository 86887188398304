import React, { useEffect, useRef, useState } from 'react';

import { Button, Layout, message, Modal, PageHeader, Progress, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { CloudUploadOutlined, PlusOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';

import apiAnyList from '../../../../api/AnyList';
import { apiPost } from '../../../../api/index';
import { subscribeToChannel, unsubscribeToChannel } from '../../../../api/sio';
import { notifyLastForRoleState } from '../../../../state/notify-state';
import GTooltip from '../../../components/GTooltip/GTooltip';
import XDataTable from '../../../components/xdf-antd/XDataTable';
import XDataUpload from '../../../components/xdf-antd/XDataUpload/XDataUpload';
import { USER_ROLE } from '../../../constants';
import MainCatalogEditDlg from './MainCatalogEditDlg';
import { commonColumns, mainCatalogFormProps } from './table-defs';

import styles from './styles.scss';
import { useInterval } from 'react-use';

const MAX_FILE_SIZE = 20 * 1024 * 1024;

export default function MainCatalogList() {
  const notifyLastForRole = useRecoilValue(notifyLastForRoleState(USER_ROLE.CATALOG_MAIN));

  const undoInlineEdit = useRef(null);
  const [inlineEditVal, setInlineEditVal] = useState({});
  const [uploadDlgVisible, setUploadDlgVisible] = useState(false);
  const [startUploading, setStartUploading] = useState(false);
  const [importing, setImporting] = useState('');
  const [importState, setImportState] = useState({});

  const [editDlg, setEditDlg] = useState({ visible: false, model: {} });

  const onRowClick = (item) => {
    setEditDlg({ visible: true, model: item });
  };

  const addRecord = () => {
    setEditDlg({ visible: true, model: {} });
  };

  const onCloseDlg = () => {
    setEditDlg({ ...editDlg, visible: false });
  };

  // const downloadFile = () => {
  //   window.open('/api/1/catalog/main/export', '_blank');
  // };

  const onInlineEditChanged = (changes, undoInlineEditFun) => {
    undoInlineEdit.current = undoInlineEditFun;
    setInlineEditVal(changes);
  };

  const onUndoInlineEdit = () => {
    if (undoInlineEdit.current) {
      undoInlineEdit.current();
    }
  };

  const onSaveMulti = async () => {
    const { err, errmsg } = await apiPost('/catalog/main/multi/set', {
      list: Object.keys(inlineEditVal).map((idp) => {
        const [id, prop] = idp.split(':');
        return {
          id,
          prop,
          val: inlineEditVal[idp],
        };
      }),
    });
    if (err) {
      if (Array.isArray(errmsg)) {
        for (const em of errmsg) {
          message.error(em.message, 4);
        }
      } else {
        message.error(errmsg || `Net error: ${err}`);
      }
    } else {
      onUndoInlineEdit();
    }
  };

  const progressState = async (token) => {
    try {
      const { err, data } = await apiPost('/progress/get', {
        token,
      });
      if (!err) {
        setImportState(data);
        if (data.done) {
          setImporting('');
          setUploadDlgVisible(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useInterval(
    () => {
      progressState(importing);
    },
    importing ? 5000 : null,
  );

  const uploadFile = () => {
    setUploadDlgVisible(true);
  };

  const closeUploadDialog = () => {
    setUploadDlgVisible(false);
  };

  const onSaveFileInfo = (i) => {
    setImporting(i.token);
  };

  const onStartUpload = () => {
    setStartUploading(true);
  };

  return (
    <>
      <PageHeader
        className={styles.pageHeader}
        title={<GTooltip tid="catalogs_main">Управление каталогом</GTooltip>}
        extra={[
          <Tooltip key="download" title="Загрузить файл (Excel)" onClick={uploadFile}>
            <Button type="primary" shape="circle" icon={<CloudUploadOutlined />} />
          </Tooltip>,
          <Tooltip key="save" title="Сохранить изменения">
            <Button
              shape="circle"
              style={{ marginLeft: 48 }}
              icon={<SaveOutlined />}
              disabled={Object.keys(inlineEditVal).length === 0}
              onClick={onSaveMulti}
            />
          </Tooltip>,
          <Tooltip key="undo" title="Отменить изменения">
            <Button
              shape="circle"
              icon={<UndoOutlined />}
              disabled={Object.keys(inlineEditVal).length === 0}
              onClick={onUndoInlineEdit}
            />
          </Tooltip>,
          <Tooltip key="add" title="Добавить запись">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ marginLeft: 48 }}
              onClick={addRecord}
            />
          </Tooltip>,
        ]}
      />
      <Layout.Content>
        <div className={styles.contextHolder}>
          <div className={styles.context}>
            <XDataTable
              apiLoadData={apiAnyList('KybCatalog:Main')}
              commonColumns={commonColumns(notifyLastForRole)}
              extentedColumns={null}
              initSort={['partNumber', 1]}
              initFilter={{}}
              onRowClick={onRowClick}
              sioEvents={['LIST:MAINCATALOG', subscribeToChannel, unsubscribeToChannel]}
              searchProps={[
                'partNumber',
                'firm',
                'model',
                'part',
                'series',
                'type',
                'codes',
                'version',
              ]}
              inlineEditFormProps={mainCatalogFormProps()}
              onInlineEditChanged={onInlineEditChanged}
              excelExport={{
                filename: `CatalogExport_${dayjs().format('YYYY-MM-DD')}`,
                tabname: 'Каталог',
                exportStep: 5000,
                autoWidth: true,
              }}
            />
          </div>
        </div>
      </Layout.Content>

      <MainCatalogEditDlg visible={editDlg.visible} model={editDlg.model} onClose={onCloseDlg} />

      <Modal
        title="Импорт каталога"
        open={uploadDlgVisible}
        closable={false}
        footer={[
          <Button key="back" onClick={closeUploadDialog} disabled={startUploading}>
            Закрыть
          </Button>,
        ]}
        width={400}
      >
        {!importing && (
          <div style={{ height: 200 }}>
            <XDataUpload
              uploadType="import-catalog"
              desc="Каталог"
              maxSize={MAX_FILE_SIZE}
              optimizeImage={false}
              webpImage={false}
              onSave={onSaveFileInfo}
              onStartUpload={onStartUpload}
            />
          </div>
        )}

        {!!importing && (
          <div style={{ height: 160 }}>
            <div
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: importState?.error ? 'red' : '#000',
              }}
            >
              {importState?.status || '...'}
            </div>
            <div style={{ marginTop: 20, textAlign: 'center' }}>
              <Progress type="circle" percent={importState?.progress || 0} />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
